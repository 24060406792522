<template>
  <form-login />
</template>

<script>
import FormLogin from '@/components/FormLogin.vue'

export default {
  name: 'Login',

  components: {
    FormLogin,
  },
}
</script>
